<template>
  <Terrain />
</template>

<script>
// @ is an alias to /src
import Terrain from "@/components/Terrain.vue";

export default {
  name: "TerrainView",
  components: {
    Terrain
  }
};
</script>
