<template>
  <DigiConf />
</template>

<script>
// @ is an alias to /src
import DigiConf from "@/components/DigiConf.vue";

export default {
  name: "DigiConfView",
  components: {
    DigiConf
  }
};
</script>
